import { lazy } from "react";

export const LazyLogin = lazy(() => import("../pages/login-signup/login"));
export const LazySignUp = lazy(() => import("../pages/login-signup/sign-up"));
export const LazyEmailVerification = lazy(() =>
  import("../pages/login-signup/email-verification")
);
export const LazyResetPassword = lazy(() =>
  import("../pages/login-signup/reset-password")
);
export const LazyPasswordSent = lazy(() =>
  import("../pages/login-signup/password-sent")
);
export const LazySetPassword = lazy(() =>
  import("../pages/login-signup/set-password")
);
export const LazyPhoneVerification = lazy(() =>
  import("../pages/login-signup/phone-verification")
);
export const LazyMainLayout = lazy(() => import("../pages/main-layout"));
export const LazyChapters = lazy(() => import("../pages/chapters"));
export const LazyChaptersOutlet = lazy(() =>
  import("../pages/chapters/chaptersOutlet")
);
export const LazyPasswordChanged = lazy(() =>
  import("../pages/login-signup/password-changed")
);
export const LazyTestimonials = lazy(() => import("../pages/testimonial"));
export const LazySubscriptions = lazy(() => import("../pages/subscription"));
export const LazyUserManagement = lazy(() =>
  import("../pages/user-management")
);
export const LazyReminder = lazy(() => import("../pages/reminder"));
export const LazyLegals = lazy(() => import("../pages/legals"));
export const LazyChats = lazy(() => import("../pages/chat"));
export const LazyProfile = lazy(() => import("../pages/profile"));
export const LazyProfileLayout = lazy(() =>
  import("../pages/profile/profileLayout")
);
export const LazyProfileChangePassword = lazy(() =>
  import("../pages/profile/change-password")
);
export const LazyProfileChangePlan = lazy(() =>
  import("../pages/profile/change-plan")
);
export const LazyLessons = lazy(() => import("../pages/lessons"));
export const LazySuccessPayment = lazy(() =>
  import("../pages/payment/success")
);
export const LazySuccessError = lazy(() => import("../pages/payment/error"));
export const LazyPrizing = lazy(() => import("../pages/pricing"));
export const LazyMyCoach = lazy(() => import("../pages/my-coach"));
export const LazyContactUs = lazy(() => import("../pages/contact-us"));
export const LazyIntroduction = lazy(() =>
  import("../pages/lessons/introduction")
);
export const LazyNextChapterUnlocked = lazy(() =>
  import("../pages/lessons/next-chapter-unlocked")
);
export const LazyStudentSupport = lazy(() =>
  import("../pages/student-support")
);
export const LazyAdminSupport = lazy(() => import("../pages/admin-support"));
export const LazySummary = lazy(() =>
  import("../pages/lessons/evaluation/evaluation-types/summaryEvaluation")
);
