import React from "react";
import styles from "./styles.module.scss";

import Button from "../../components/button";
import { BackButtonIcon } from "../../components/svg";
import { useNavigate } from "react-router";
import { useAuth } from "../../zustand-store/auth-store";

function PageNotFound() {
  const navigate = useNavigate();
  const { userDetail } = useAuth();
  const redirect = () => {
    if (userDetail) {
      if (userDetail.role === "ADMIN") navigate("/chat");
      else navigate("/course");
    } else {
      navigate("/");
    }
  };
  return (
    <>
      <Button className={styles.backButton} onClick={() => redirect()}>
        {" "}
        <BackButtonIcon /> Back to Home
      </Button>
      <div className={styles.imagePagenNotFoundcontainer} />
    </>
  );
}

export default PageNotFound;
