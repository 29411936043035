import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Button from "../../components/button";
import { BackButtonIcon } from "../../components/svg";
import { useNavigate } from "react-router";
import { useAuth } from "../../zustand-store/auth-store";

function WentWrongPage({ resetError }) {
  const navigate = useNavigate();
  const { userDetail } = useAuth();
  const [isNavigate, setIsNavigate] = useState(null);

  useEffect(() => {
    if (isNavigate !== null) {
      if (userDetail) {
        if (userDetail.role === "ADMIN") navigate("/chat");
        else navigate("/course");
      } else {
        navigate("/");
      }
      resetError();
    }
  }, [isNavigate]);

  const redirect = () => {
    setIsNavigate(true);
  };

  return (
    <>
      <Button className={styles.backButton} onClick={() => redirect()}>
        {" "}
        <BackButtonIcon /> Back to Home
      </Button>
      <div className={styles.imagecontainer} />
    </>
  );
}

export default WentWrongPage;
